<template>
  <main class="main education" v-if="courses.length">
    <div class="container education__container">
      <section class="education__section">
        <h1 class="education__title">Завершенные курсы</h1>
        <div class="education__list">
          <CourseProgressComponent :course="course" :key="i" v-for="(course, i) in courses" />
          <CourseBuyNewComponent />
        </div>
      </section>
    </div>
  </main>
  <main class="main education" v-else>
    <div class="empty">
      <EmptyCompletedIcon class="empty__icon" />
      <h1 class="empty__title">Нет завершенных курсов</h1>
      <span class="empty__subtitle">
        Приобрести курс можно в нашем <router-link :to="{ name: 'courses' }">каталоге</router-link>
      </span>
    </div>
  </main>
</template>

<script>
import EmptyCompletedIcon from "components/svg/EmptyCompletedIcon.vue";
import MY_COURSES from "gql/auth/my_courses.graphql";
import CourseBuyNewComponent from "@/views/auth/education/components/CourseBuyNewComponent.vue";
import CourseProgressComponent from "components/CourseProgressComponent.vue";

export default {
  async asyncData({ store, apollo, router }) {
    await apollo.defaultClient
      .query({
        query: MY_COURSES,
        variables: {
          passed: true,
        },
        context: {
          headers: {
            Authorization: "Bearer " + store.state.cookies["apollo-token"],
          },
        },
      })
      .then(({ data }) => {
        store.state.tmp.courses = data.my_courses;
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors[0] && graphQLErrors[0].message === "Unauthenticated.") {
          router.push({
            name: "home",
          });
        } else {
          store.state.tmp.haveError = true;
        }
      });
  },
  name: "AccountEducation",
  computed: {
    courses() {
      return this.$store.state.tmp.courses;
    },
  },
  mounted() {
    if (this.$store.state.tmp.haveError) {
      this.$notify({
        title: "Ошибка",
        text: "Во время загрузки данных произошла ошибка, попробуйте перезагрузить страницу",
        duration: 8000,
        speed: 200,
        type: "error",
      });
    }
  },
  components: {
    CourseProgressComponent,
    CourseBuyNewComponent,
    EmptyCompletedIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/education.styl"
.empty {
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding 40px 32px 100px
  +below(640px) {
    padding 30px 20px 50px
  }

  &__icon {
    max-width 600px
    width 100%

    svg {
      width 100%
      height 100%
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
    line-height: 44px;
    color: var(--black);
    margin 0 0 15px
    text-align center
    +below(640px) {
      font-size: 1.5em;
      line-height: 29px;
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 22px;
    text-align center
    color: var(--black);
    +below(640px) {
      font-size 16px
      line-height 19px
    }

    a {
      color var(--main_color)
      text-decoration underline
      text-decoration-color var(--border_color)
      text-decoration-style solid
    }
  }
}
</style>
